type AppVersion = {
  name: string;
  version: string;
};

export const useAppVersion = async (): Promise<AppVersion> => {
  const config = useRuntimeConfig();

  let version: AppVersion = {
    name: "ots-localhost",
    version: "dev",
  };

  if (config.public.deployment !== "local") {
    const url = useCdnUrl() === "" ? "http://localhost:3000" : `${useCdnUrl()}`;
    version = await $fetch(`${url}/version.json`);
  }

  console.log("App:", version.name, "version:", version.version, "env:", config.public.deployment);
  return version;
};
