<template>
  <button class="close-button">
    &#x2715;
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.414"
      height="49.414"
      viewBox="0 0 49.414 49.414"
    >
      <g id="CLOSE" transform="translate(-1423.793 -399.793)">
        <line
          id="Line_30"
          data-name="Line 30"
          x2="48"
          y2="48"
          transform="translate(1424.5 400.5)"
          fill="none"
          :stroke="`${$attrs.color ? $attrs.color : 'black'}`"
          stroke-width="2"
        />
        <line
          id="Line_31"
          data-name="Line 31"
          x2="48"
          y2="48"
          transform="translate(1472.5 400.5) rotate(90)"
          fill="none"
          :stroke="`${$attrs.color ? $attrs.color : 'black'}`"
          stroke-width="2"
        />
      </g>
    </svg> -->
  </button>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
.close-button {
  background: none;
  border: none;
  cursor: pointer;

  font-size: 2rem;
  font-weight: 800;
  height: 60px;
  user-select: none;
  width: 60px;

  @include queries.for-size(tablet-landscape-down) {
    height: 40px;
    width: 40px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
