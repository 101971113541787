export const locales = [
	"AT", //Austria
	"BE", //Belgium
	"BG", //Bulgaria
	"HR", //Croatia
	"CY", //Cyprus
	"CZ", //Czech Republic
	"DK", //Denmark
	"EE", //Estonia
	"FI", //Finland
	"FR", //France
	"DE", //Germany
	"GR", //Greece
	"HU", //Hungary
	"IE", //Ireland //Northern Ireland
	"IT", //Italy
	"LV", //Latvia
	"LT", //Lithuania
	"LU", //Luxembourg
	"MT", //Malta
	"NL", //Netherlands
	"PL", //Poland
	"PT", //Portugal, Azores, Madeira
	"RO", //Romania
	"SK", //Slovakia
	"SI", //Slovenia
	"ES", //Spain
	"SW", //Sweden
	"GB", //England, United Kingdom, Scotland, Wales
];