import validate from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/home/vsts/work/1/s/webapp-ots-nuxt/middleware/analytics.global.ts";
import fix_45scroll_45position_45global from "/home/vsts/work/1/s/webapp-ots-nuxt/middleware/fix-scroll-position.global.ts";
import manifest_45route_45rule from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  fix_45scroll_45position_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/middleware/auth.ts")
}