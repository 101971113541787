export const appCdnURL = ""

export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"x-ua-compatiable","content":"IE=edge"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#000000"},{"hid":"description","name":"description","content":"A modern-day visitor center, at the intersection of Art, Technology, and Entertainment"},{"hid":"og:title","name":"og:title","content":"One Times Square"},{"hid":"og:description","name":"og:description","content":"A modern-day visitor center, at the intersection of Art, Technology, and Entertainment"},{"hid":"og:image","name":"og:image","content":"https://static.ots-dev.zenapptic.dev/ots-social-card.jpg"},{"hid":"og:url","name":"og:url","content":"https://www.onetimessquare.com"},{"hid":"twitter:card","name":"twitter:card","content":"https://static.ots-dev.zenapptic.dev/ots-social-card.jpg"},{"hid":"twitter:image:alt","name":"twitter:image:alt","content":"One Times Square"},{"hid":"og:site_name","name":"og:site_name","content":"One Times Square"}],"link":[{"rel":"icon","type":"image/x-icon","href":"https://static.ots-dev.zenapptic.dev/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"One Times Square","htmlAttrs":{"lang":"en-US"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"