<script setup lang="ts">
import type { NuxtError } from "#app";

type ErrorProps = {
  error: NuxtError;
};

const props = defineProps<ErrorProps>();

console.log("error: ", props.error);
</script>

<template>
  <div>
    <h1>{{ error.statusCode }}</h1>
    <NuxtLink to="/">Go back home</NuxtLink>
  </div>
</template>
