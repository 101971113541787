<template>
  <button class="hamburger-button">
    <div />
    <div />
    <div />
  </button>
</template>

<style lang="scss" scoped>
@use "../../assets/scss/queries";
.hamburger-button {
  align-items: center;
  background-color: black;
  border: 3px white solid;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  height: 60px;
  justify-content: center;
  width: 60px;

  @include queries.for-size(tablet-landscape-down) {
    border-width: 2px;
    height: 40px;
    width: 40px;
  }

  div {
    background-color: white;
    border-radius: 1.5px;
    height: 3px;
    width: 30px;

    @include queries.for-size(tablet-landscape-down) {
      width: 20px;
    }
  }

  @include queries.for-size(tablet-landscape-down) {
    display: flex;
  }
}
</style>
